import { gql } from '@apollo/client'

const GET_HERO_CONTENT_CARD = /* GraphQL */ gql`
  query content($arguments: GetContent!) {
    content(arguments: $arguments) {
      type
      uuid
      slug
      sponsor
      theme
      image {
        main
        cropped
        hero
      }
      categories {
        description
        image
        name
        parentUuid
        position
        slug
        uuid
      }
      flags {
        isFeatured
      }
      title {
        long
        short
      }
    }
  }
`

export default GET_HERO_CONTENT_CARD
