import PropTypes from 'prop-types'
import React from 'react'
import {
  HeroArticleCard,  
  Link,
  ListArticles,
  ListCategories,
  ListInstalooks,
  ListTrends
} from 'Blocks'
import { withGAEvent } from 'Utils'
import config, { gaSettings } from 'Config'

const {
  homePage: { retailerLinks }
} = gaSettings

const HomePage = ({ className }) => (
  <div className={`${className} homepage-container`}>
    {config.heroArticleEnabled && <HeroArticleCard />}

    <ListArticles className="container" section="first" />
    
    <div className="container__outer--black">
      <ListInstalooks className="container" />
    </div>

    <ListArticles className="container" section="second" />

    <ListTrends className="container" />

    <div className="container__outer--grey">
      <ListCategories className="container" />
    </div>

    <HomeFooter stores={config.home.stores} />
  </div>
)

HomePage.propTypes = {
  className: PropTypes.string
}

const HomeFooter = ({ stores }) => (
  <div className="home-footer__wrapper">
    <div className="home-footer">
      <h3 className="home-footer__heading">{config.home.storesTitle}</h3>
      <div className="home-footer__grid">
        {stores.map((store, index) => {
          const GALink = withGAEvent(Link, {
            category: retailerLinks.category,
            action: retailerLinks.action,
            label: `linkName=${store.name} - destinationUrl=${store.to} - Category Panel Link`,
            additionalProps: {
              linkName: store.name,
              destinationUrl: store.to,
            },
          })
          return (
            <GALink
              key={`${store.name}-store-${index}`}
              to={store.to}
              target="_blank"
              className="home-footer__store"
            >
              <img src={store.logo} alt={`${store.name} brand logo`} />
            </GALink>
          )
        })}
      </div>
    </div>
  </div>
)

HomeFooter.propTypes = {
  stores: PropTypes.array
}

export default HomePage
